import React, { useState, useEffect, useCallback } from 'react';
import {
    AppBar,
    Box,
    Container,
    InputBase,
    Toolbar,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import Navbar from "../../components/Navbar";
import { listUserVideos, userVideoDelete, compareVideos, listInstructorsVideos } from "../../utils/apiRoutes";
import { ToastContainer } from 'react-toastify';
import { createNotification } from '../../utils/alerts';
import VideoList from '../../components/VideosList';
import { userData } from '../../utils/userData';
import HorizontalLinearStepper from '../../components/Stepper';
import ComparedVideosBoxes from '../../components/ComparedVideosBoxes';
import 'react-toastify/dist/ReactToastify.css';
import styles from './compare.module.scss';

let timeoutId;

const ComparePage = () => {
    const isMobile = window.innerWidth < 768;
    const [user, setUser] = useState(userData());
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(6);
    const [videos, setVideos] = useState([]);
    const [videoToDelete, setVideoToDelete] = useState(null);
    const [emptyVideos, setEmptyVideos] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [comparedVideoId, setComparedVideoId] = useState(null);
    const [instructorVideoId, setInstructorVideoId] = useState(null);
    const [title, setTitle] = useState('');
    const [blockStep, setBlockStep] = useState(true);
    const [videosFrom, setVideosFrom] = useState('student');

    const getVideos = async (userId, searchTerm) => {
        try {
            const response = await listUserVideos(userId, {
                search: searchTerm === '' ? undefined : searchTerm,
                perPage: itemsPerPage
            });
            setVideos(response.videos);
        } catch (error) {
            createNotification('failed', 'Falha ao buscar os vídeos!', isMobile);
        }
    };

    const loadMoreVideos = useCallback(async (userId) => {
        if (loading || emptyVideos) return;
        setLoading(true);
        const nextPage = page + 1;
        let response;

        if (videosFrom == 'instructor') {
            response = await listInstructorsVideos({
                search: searchTerm === '' ? undefined : searchTerm,
                page: nextPage,
                perPage: itemsPerPage
            });
        } else if (videosFrom == 'student') {
            response = await listUserVideos(userId, {
                search: searchTerm === '' ? undefined : searchTerm,
                page: nextPage,
                perPage: itemsPerPage
            });
        }

        setTimeout(() => {
            setVideos(prevVideos => [...prevVideos, ...response.videos]);
            setPage(nextPage);
            setLoading(false);
            setEmptyVideos(response.videos.length == 0)
        }, 500);
    });

    const handleSearchChange = (event) => {
        clearTimeout(timeoutId);
        setSearchTerm(event.target.value);
        setEmptyVideos(false);

        timeoutId = setTimeout(() => {
            if (videosFrom == 'instructor') {
                getInstructorsVideos(event.target.value);
            } else if (videosFrom == 'student') {
                getVideos(user.id, event.target.value);
            }
        }, 1000);
    };

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
        if (bottom) {
            loadMoreVideos(user.id);
        }
    };

    const handleDeleteClick = (event, userVideoId) => {
        setAnchorEl(event.currentTarget);
        setVideoToDelete(userVideoId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setVideoToDelete(null);
    };

    const handleConfirmDelete = async () => {
        if (videoToDelete) {
            await userVideoDelete(user.id, videoToDelete);
            setVideos(videos.filter(video => video.id !== videoToDelete));
            createNotification('success', 'Vídeo excluído!', isMobile);
        }
        handleClose();
    };

    useEffect(() => {
        setComparedVideoId(localStorage.getItem('comparedVideoId') ?? null)
    }, []);

    const getInstructorsVideos = async (searchTerm) => {
        try {
            const response = await listInstructorsVideos({
                search: searchTerm === '' ? undefined : searchTerm,
                perPage: itemsPerPage
            });
            setVideos(response.videos);
        } catch (error) {
            console.error('Error fetching instructors videos:', error);
        }
    };

    useEffect(() => {
        setSearchTerm('');

        if (videosFrom == 'instructor') {
            getInstructorsVideos();
        } else if (videosFrom == 'student') {
            getVideos(user.id);
        }
    }, [videosFrom])

    const handleConfirmComparison = async () => {
        try {
            if (title === '') {
                createNotification('error', 'Insira um título para a comparação', isMobile);
                return;
            }

            setLoading(true);
            const studentVideoId = localStorage.getItem('comparedVideoId');
            const instructorVideoId = localStorage.getItem('instructorVideoId');

            const payload = {
                instructorVideoId: instructorVideoId,
                studentVideoId: studentVideoId,
                title: title
            };

            const response = await compareVideos(payload);

            localStorage.removeItem('comparedVideoId');
            localStorage.removeItem('instructorVideoId');

            timeoutId = setTimeout(() => {
                window.location.href = `/analysis/${response.analysis_id}`
            }, 1000);
        } catch (error) {
            createNotification('error', 'Erro ao criar análise de comparação.', isMobile);
            setLoading(false);
        }
    }

    return (
        <>
            <Navbar />
            <Container className={styles["container"]} maxWidth="" >
                <HorizontalLinearStepper
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    blockStep={blockStep}
                    handleConfirmComparison={handleConfirmComparison}
                    loading={loading}
                    setVideosFrom={setVideosFrom}
                />

                {activeStep != 2 && (
                    <AppBar position="static" className={styles["appbar"]}>
                        <Toolbar className={styles["toolbar"]}>
                            {isMobile && (
                                <>
                                    <Box className={styles["search-box"]}>
                                        <InputBase
                                            className={styles["search-input"]}
                                            placeholder="Pesquisar..."
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                            inputProps={{ 'aria-label': 'pesquisar' }}
                                            startAdornment={<SearchIcon />}
                                        />
                                        {/* <FormControl className="user-type-select">
                                        <InputLabel id="user-type-label">Tipo de Usuário</InputLabel>
                                        <Select
                                            labelId="user-type-label"
                                            id="user-type"
                                            value={searchUserType}
                                            onChange={handleUserTypeChange}
                                        >
                                            <MenuItem key='0' value='Todos'>Todos</MenuItem>
                                            
                                        </Select>
                                    </FormControl> */}
                                    </Box>
                                </>
                            )}
                            {!isMobile && (
                                <>
                                    <Box className={styles["search-box"]}>
                                        <InputBase
                                            className={styles["search-input"]}
                                            placeholder="Pesquisar..."
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                            inputProps={{ 'aria-label': 'pesquisar' }}
                                            startAdornment={<SearchIcon />}
                                        />
                                        {/* <FormControl className="user-type-select">
                                        <InputLabel id="user-type-label">Tipo de Usuário</InputLabel>
                                        <Select
                                            labelId="user-type-label"
                                            id="user-type"
                                            value={searchUserType}
                                            onChange={handleUserTypeChange}
                                        >
                                            <MenuItem key='0' value='Todos'>Todos</MenuItem>
                                            
                                        </Select>
                                    </FormControl> */}
                                    </Box>
                                </>
                            )}
                        </Toolbar>
                    </AppBar>
                )}

                {activeStep == 0 && (
                    <VideoList
                        videos={videos}
                        handleScroll={handleScroll}
                        loading={loading}
                        handleClose={handleClose}
                        handleDeleteClick={handleDeleteClick}
                        handleConfirmDelete={handleConfirmDelete}
                        anchorEl={anchorEl}
                        setComparedVideoId={setComparedVideoId}
                        comparedVideoId={comparedVideoId}
                        setBlockStep={setBlockStep}
                        videosFrom={videosFrom}
                    />
                )}

                {activeStep == 1 && (
                    <VideoList
                        videos={videos}
                        handleScroll={handleScroll}
                        loading={loading}
                        setInstructorVideoId={setInstructorVideoId}
                        instructorVideoId={instructorVideoId}
                        videosFrom={videosFrom}
                        setBlockStep={setBlockStep}
                    />
                )}

                {activeStep == 2 && (
                    <ComparedVideosBoxes
                        title={title}
                        setTitle={setTitle}
                    />
                )}

            </Container>
            <ToastContainer />
        </>
    );
};

export default ComparePage;
