import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import IbrasurfLogo from '../IbrasurfLogo';
import { logout } from '../../utils/apiRoutes';
import './navbar.scss';

import { userData } from '../../utils/userData';
import { UserTypeEnum } from '../../enums/userType';

function Navbar() {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [user, setUser] = useState(null);
    const [userImage, setUserImage] = useState('../../assets/default_user.png');

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = async () => {
        try {
            await logout();
            localStorage.removeItem('user');
            window.location.href = '/login';
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        setUser(userData());
    }, []);

    useEffect(() => {
        if (user) {
            setUserImage(user.thumb_link);
        }
    }, [user]);

    return (
        <Box className="navbar-box">
            <AppBar position="fixed">
                <Container maxWidth={false}>
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/home"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <IbrasurfLogo fillColor="#FFFFFF" width="80px" />
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <MenuItem key='home' onClick={() => { window.location.href = '/home' }}>
                                    <Typography textAlign="center">Início</Typography>
                                </MenuItem>
                                <MenuItem key='my-videos' onClick={() => { window.location.href = '/my-videos' }}>
                                    <Typography textAlign="center">Meus Vídeos</Typography>
                                </MenuItem>
                                <MenuItem key='analysis' onClick={() => { window.location.href = '/analysis' }}>
                                    <Typography textAlign="center">Análises</Typography>
                                </MenuItem>
                                {user && user.user_type === UserTypeEnum.ADMIN &&
                                    (
                                        <MenuItem key='users' onClick={() => { window.location.href = '/users' }}>
                                            <Typography textAlign="center">Usuários</Typography>
                                        </MenuItem>
                                    )
                                }
                            </Menu>
                        </Box>

                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <IbrasurfLogo fillColor="#FFFFFF" width="50px" />
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            <Button
                                key='inicio'
                                onClick={() => { window.location.href = '/home' }}
                                sx={{ my: 2, display: 'block' }}
                                className="menu-button"
                            >
                                Início
                            </Button>
                            <Button
                                key='my-videos'
                                onClick={() => { window.location.href = '/my-videos' }}
                                sx={{ my: 2, display: 'block' }}
                                className="menu-button"
                            >
                                Meus Vídeos
                            </Button>
                            <Button
                                key='analysis'
                                onClick={() => { window.location.href = '/analysis' }}
                                sx={{ my: 2, display: 'block' }}
                                className="menu-button"
                            >
                                Análises
                            </Button>
                            {user && user.user_type === UserTypeEnum.ADMIN &&
                                (
                                    <Button
                                        key='cadastrar-usuarios'
                                        onClick={() => { window.location.href = '/users' }}
                                        sx={{ my: 2, display: 'block' }}
                                        className="menu-button"
                                    >
                                        Usuários
                                    </Button>
                                )
                            }
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar src={userImage} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem key='logout' onClick={handleLogout}>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
                <div className="yellow-underline"></div>
            </AppBar>
        </Box>
    );
}

export default Navbar;
