const UserTypeEnum = {
    ADMIN: 'ADMIN',
    INSTRUCTOR: 'INSTRUCTOR',
    STUDENT: 'STUDENT',
};

const translateUserType = (value) => {
    const translations = {
        ADMIN: 'Administrador',
        INSTRUCTOR: 'Instrutor',
        STUDENT: 'Aluno'
    };
    return translations[value] || value;
};

export { UserTypeEnum, translateUserType };
