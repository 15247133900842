import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Box, Button, IconButton, Popover, CircularProgress } from '@mui/material';
import { listUserVideos, listInstructorsVideos } from '../../utils/apiRoutes';
import DeleteIcon from '@mui/icons-material/Delete';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import Schedule from '@mui/icons-material/Schedule';
import moment from 'moment';
import { StatusEnum } from '../../enums/status';
import { translateWaveSide } from '../../enums/waveSide'
import './videos-list.scss';

const VideoList = ({
    videos,
    loading,
    handleScroll,
    handleClose,
    handleDeleteClick,
    handleConfirmDelete,
    anchorEl,
    setComparedVideoId,
    comparedVideoId = null,
    setInstructorVideoId,
    instructorVideoId = null,
    videosFrom,
    setBlockStep
}) => {
    const location = useLocation();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        if (comparedVideoId && videosFrom === 'student') {
            setBlockStep(false);
        }
    }, [comparedVideoId])

    useEffect(() => {
        if (!instructorVideoId && videosFrom === 'instructor') {
            setBlockStep(true);
        }
    }, [instructorVideoId])

    const handleCompareVideo = (videoId) => {
        if (videosFrom == 'student') {
            localStorage.setItem('comparedVideoId', videoId);
            setComparedVideoId(videoId);
            setBlockStep(false);
        } else if (videosFrom == 'instructor') {
            localStorage.setItem('instructorVideoId', videoId);
            setInstructorVideoId(videoId);
            setBlockStep(false);
        }

        if (location.pathname !== '/analysis/compare')
            window.location.href = '/analysis/compare'
    }

    return (
        <Box mt={2}>
            <div className="video-container" onScroll={handleScroll}>
                {videos.length === 0 && (
                    <Typography variant="body1" sx={{ mt: 2, textAlign: 'center', width: '100%' }}>
                        Nenhum vídeo encontrado
                    </Typography>
                )}
                {videos.map((video) => (
                    <Card className="video-card" key={video.id}>
                        <CardMedia
                            component="video"
                            controls
                            src={video.keypoints_status === StatusEnum.COMPLETED ? video.video_keypoints_link : video.video_link}
                            alt={video.title}
                        />
                        <CardContent className="card-content">
                            <Box className="title-box">
                                <Typography variant="h6" component="div" className="truncated-title" title={video.title}>
                                    {video.title}
                                </Typography>
                            </Box>
                            <Box className="description-box">
                                <Typography
                                    className="truncated-description"
                                    variant="body2"
                                    title={`Lado da onda: ${translateWaveSide(video.wave_side)}`}
                                >
                                    Lado da onda: {translateWaveSide(video.wave_side)}
                                </Typography>
                                {video.wave_type && (
                                    <Typography
                                        className="truncated-description"
                                        variant="body2"
                                        title={`Tipo da onda: ${video.wave_type}`}
                                    >
                                        Tipo da onda: {video.wave_type}
                                    </Typography>
                                )}
                            </Box>
                            {location.pathname !== '/analysis/compare' && (
                                <>
                                    <Box className="status-container">
                                        {video.keypoints_status === StatusEnum.TO_PROCESS && (
                                            <Box className="status to-process">
                                                <HourglassEmpty />
                                                <Typography variant="body2">Aguardando para gerar keypoints</Typography>
                                            </Box>
                                        )}
                                        {video.keypoints_status === StatusEnum.PROCESSING && (
                                            <Box className="status processing">
                                                <CircularProgress size="1em" />
                                                <Typography variant="body2">Gerando keypoints</Typography>
                                            </Box>
                                        )}
                                        {video.keypoints_status === StatusEnum.COMPLETED && (
                                            <Box className="status completed">
                                                <TaskAltIcon />
                                                <Typography variant="body2">Keypoints processados</Typography>
                                            </Box>
                                        )}
                                        {video.keypoints_status === StatusEnum.FAILED && (
                                            <Box className="status failed">
                                                <CancelIcon />
                                                <Typography variant="body2">Erro ao gerar keypoints</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box className="date-container">
                                        <Box className="date">
                                            <Schedule />
                                            <Typography variant="body2">Atualizado em {moment(video.updated_at).format('DD/MM/YYYY')}</Typography>
                                        </Box>
                                    </Box>
                                </>
                            )}

                            <Box className="button-container">
                                {location.pathname !== '/analysis/compare' && (
                                    <>
                                        <Button className="edit-button" onClick={() => window.location.href = `/my-videos/${video.id}`}>
                                            Ver detalhes
                                        </Button>
                                        <Button
                                            className="edit-button"
                                            onClick={() => { handleCompareVideo(video.id) }}
                                            disabled={video.keypoints_status != StatusEnum.COMPLETED}
                                        >
                                            Comparar
                                        </Button>
                                        <IconButton
                                            className="delete-button"
                                            aria-label="delete"
                                            disabled={video.keypoints_status == StatusEnum.PROCESSING}
                                            onClick={(e) => handleDeleteClick(e, video.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </>
                                )}

                                {location.pathname === '/analysis/compare' && (
                                    <>
                                        {comparedVideoId == video.id || instructorVideoId == video.id

                                            ? <Button
                                                style={{ backgroundColor: 'green', color: 'white', width: '100%' }}
                                                disabled={true}
                                            >
                                                Selecionado <TaskAltIcon style={{ paddingLeft: '1%' }} />
                                            </Button>
                                            : <Button
                                                style={{ width: '100%' }}
                                                onClick={() => { handleCompareVideo(video.id) }}
                                                disabled={video.keypoints_status != StatusEnum.COMPLETED}
                                            >
                                                Comparar
                                            </Button>
                                        }

                                    </>
                                )}

                            </Box>
                        </CardContent>
                    </Card>
                ))}
                {loading && <CircularProgress sx={{ display: 'block', mx: 'auto' }} />}
                <Popover
                    id={id}
                    className="popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography className="popover-box">Você realmente deseja excluir este registro?</Typography>
                    <Box className="popover-buttons">
                        <Button onClick={handleClose} className="cancel-button">
                            Cancelar
                        </Button>
                        <Button onClick={handleConfirmDelete} className="confirm-button">
                            Excluir
                        </Button>
                    </Box>
                </Popover>
            </div>
        </Box>
    );
};

export default VideoList;
