import React, { useEffect, useState } from "react";
import './home-page.scss';
import Navbar from "../../components/Navbar";

function HomePage() {
    return (<>
        <Navbar />
        <div>Home</div>
    </>

    );
}

export default HomePage;
