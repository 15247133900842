import React from 'react';
import Navbar from "../../components/Navbar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ComparedVideosBoxes from '../../components/ComparedVideosBoxes';

const ComparedDetailPage = () => {
    return (
        <>
            <Navbar />
            <ComparedVideosBoxes />
            <ToastContainer />
        </>
    );
};

export default ComparedDetailPage;
