
import React from 'react';
import { Box } from '@mui/material';

const AnalysisTabPanel = ({ children, value, index }) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && (
                <Box p={3}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

export default AnalysisTabPanel;