import React, { useState, useEffect, useCallback } from 'react';
import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    InputBase,
    Paper,
    Toolbar,
    Typography,
    CircularProgress,
    Popover
} from '@mui/material';
import { Search as SearchIcon, Visibility as VisibilityIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import Navbar from "../../components/Navbar";
import { Link } from 'react-router-dom';
import { listAnalysis, deleteAnalysis } from "../../utils/apiRoutes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createNotification } from '../../utils/alerts';
import styles from './list-analysis.module.scss';
import { translateStatus } from '../../enums/status';


import moment from 'moment';

let timeoutId;

const ListAnalysis = () => {
    const isMobile = window.innerWidth < 768;
    const [searchTerm, setSearchTerm] = useState('');
    const [analysisList, setAnalysisList] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [analysisToDelete, setAnalysisToDelete] = useState(null);
    const [emptyAnalysis, setEmptyAnalysis] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const getAnalysis = async (searchTerm) => {
        try {
            setLoading(true);

            const response = await listAnalysis({
                perPage: itemsPerPage,
                search: searchTerm === '' ? undefined : searchTerm
            });

            setAnalysisList(response.analysis);
            setEmptyAnalysis(response.analysis.length == 0)
            setPage(1)
        } catch (error) {
            createNotification('error', 'Erro ao buscar análises', isMobile);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAnalysis();
    }, []);

    const loadMoreAnalysis = useCallback(async () => {
        if (loading || emptyAnalysis) return;
        setLoading(true);
        const nextPage = page + 1;

        const response = await listAnalysis({
            search: searchTerm,
            page: nextPage,
            perPage: itemsPerPage
        });

        setTimeout(() => {
            setAnalysisList(prevAnalysis => [...prevAnalysis, ...response.analysis]);
            setPage(nextPage);
            setLoading(false);
            setEmptyAnalysis(response.analysis.length == 0)
        }, 500);
    });

    const handleSearchChange = (event) => {
        clearTimeout(timeoutId);
        setSearchTerm(event.target.value);

        timeoutId = setTimeout(() => {
            getAnalysis(event.target.value);
        }, 1000);
    };

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
        if (bottom) {
            loadMoreAnalysis();
        }
    };

    const handleDelete = (event, analysis) => {
        setAnchorEl(event.currentTarget);
        setAnalysisToDelete(analysis);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnalysisToDelete(null);
    };

    const handleConfirmDelete = async () => {
        if (analysisToDelete) {
            await deleteAnalysis(analysisToDelete.id);
            setAnalysisList(analysisList.filter(analysis => analysis.id !== analysisToDelete.id));
            createNotification('success', 'Análise excluída!', isMobile);
        }
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Navbar />
            <Container className={styles["container"]} maxWidth="" >
                <AppBar position="static" className={styles["appbar"]}>
                    <Toolbar className={styles["toolbar"]}>
                        {isMobile && (
                            <>
                                <Button
                                    variant="contained"
                                    className={styles["add-button"]}
                                    endIcon={<AddIcon />}
                                    component={Link}
                                    to="/analysis/compare"
                                >
                                    Comparar
                                </Button>
                                <Box className={styles["search-box"]}>
                                    <InputBase
                                        className={styles["search-input"]}
                                        placeholder="Pesquisar..."
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        inputProps={{ 'aria-label': 'pesquisar' }}
                                        startAdornment={<SearchIcon />}
                                    />
                                </Box>
                            </>
                        )}
                        {!isMobile && (
                            <>
                                <Box className={styles["search-box"]}>
                                    <InputBase
                                        className={styles["search-input"]}
                                        placeholder="Pesquisar..."
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        inputProps={{ 'aria-label': 'pesquisar' }}
                                        startAdornment={<SearchIcon />}
                                    />
                                </Box>
                                <Button
                                    variant="contained"
                                    className={styles["add-button"]}
                                    endIcon={<AddIcon />}
                                    component={Link}
                                    to="/analysis/compare"
                                >
                                    Comparar
                                </Button>
                            </>
                        )}
                    </Toolbar>
                </AppBar>
                <Box mt={2} className={styles["analysis-list"]} onScroll={handleScroll}>
                    {analysisList.length === 0 && !loading && (
                        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                            Nenhuma análise encontrada
                        </Typography>
                    )}
                    {analysisList.map(analysis => (
                        <Paper key={analysis.id} className={styles["analysis-card"]}>
                            <Box className={styles["analysis-card-info"]}>
                                <Typography variant="h6">{analysis.title}</Typography>
                                <Typography variant="body2">Status: {translateStatus(analysis.status)}</Typography>
                                <Typography variant="body2">Criada em: {moment(analysis.created_at).format('DD/MM/YYYY [às] HH:mm:ss')}</Typography>
                                <Typography variant="body2">Atualizada em: {moment(analysis.updated_at).format('DD/MM/YYYY [às] HH:mm:ss')}</Typography>
                            </Box>
                            <IconButton className={styles["show-button"]} aria-label="edit" onClick={() => { window.location.href = `/analysis/${analysis.id}` }}>
                                <VisibilityIcon />
                            </IconButton>
                            <IconButton className={styles["delete-button"]} aria-label="delete" onClick={(e) => handleDelete(e, analysis)}>
                                <DeleteIcon />
                            </IconButton>
                        </Paper>
                    ))}
                    {loading && <CircularProgress sx={{ display: 'block', mx: 'auto' }} />}
                </Box>
            </Container>

            <Popover
                id={id}
                className={styles["popover"]}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={styles["popover-box"]}>Você realmente deseja excluir este registro?</Typography>
                <Box className={styles["popover-buttons"]}>
                    <Button onClick={handleClose} className={styles["cancel-button"]}>
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirmDelete} className={styles["confirm-button"]}>
                        Excluir
                    </Button>
                </Box>
            </Popover>

            <ToastContainer />
        </>
    );
};

export default ListAnalysis;
