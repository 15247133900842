import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    TextField,
    Typography,
    IconButton,
    CircularProgress,
    MenuItem,
    Select,
    FormControl,
    InputLabel
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../../components/Navbar";
import { userRegister, userUpdate } from "../../utils/apiRoutes"
import { UserTypeEnum, translateUserType } from '../../enums/userType';
import { getUser } from "../../utils/apiRoutes"
import { createNotification } from "../../utils/alerts";
import './user.scss';

const Input = styled('input')({
    display: 'none',
});

const UserPage = () => {
    const { userId } = useParams();
    const isMobile = window.innerWidth < 768;
    const [avatar, setAvatar] = useState(null);
    const [avatarFile, setAvatarFile] = useState(null);
    const [name, setName] = useState('');
    const [cellphone, setCellphone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userType, setUserType] = useState(UserTypeEnum.STUDENT);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userId != undefined) {
            getUserInformation(userId);
        }
    }, [userId])

    const getUserInformation = async (userId) => {
        try {
            const response = await getUser(userId);

            setName(response.name);
            setEmail(response.email);
            setCellphone(response.cellphone ?? '');
            setUserType(response.user_type);
            setAvatar(response.image_link);
        } catch (error) {
            createNotification('error', 'Não foi possível recuperar as informações do usuário.', isMobile);
        }
    }

    const handleAvatarChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setAvatarFile(file);
            const reader = new FileReader();
            reader.onload = () => setAvatar(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const handleCellphoneChange = (e) => {
        const value = e.target.value;
        // Permitir apenas números
        const regex = /^[0-9\b]+$/;
        if (value === '' || regex.test(value)) {
            setCellphone(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!userId || password != '') {
            if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password)) {
                setErrorMessage('A senha deve ter no mínimo 8 dígitos contendo letras e números');
                return;
            }

            if (password !== confirmPassword) {
                setErrorMessage('As senhas não coincidem');
                return;
            }
        }

        setErrorMessage('');

        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('userType', userType);
            formData.append('cellphone', cellphone ?? '');

            if (avatarFile) {
                formData.append('file', avatarFile);
            }

            let message = '';

            if (userId !== undefined) { // Atualização
                message = 'Usuário atualizado com sucesso!'
                if (password) {
                    formData.append('password', password);
                }

                if (confirmPassword) {
                    formData.append('passwordConfirmation', confirmPassword);
                }

                const response = await userUpdate(formData, userId);
            } else { // Cadastro
                message = 'Usuário cadastrado com sucesso!';

                formData.append('password', password);
                formData.append('passwordConfirmation', confirmPassword);

                const response = await userRegister(formData);
            }

            createNotification('success', message, isMobile);

            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (error) {
            console.log("error", error)
            let message = error.response?.status === 400
                ? error.response.data.error
                : 'Erro ao realizar cadastro. Por favor, tente novamente.'

            createNotification('error', message, isMobile);
            setLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <Box className="user-box"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    boxShadow={3}
                    p={4}
                    borderRadius={2}
                    sx={{ width: { xs: '80%', sm: '70%', md: '50%', lg: '60%', xl: '60%' } }}
                >
                    {isMobile && (
                        <Typography variant="h5" component="div" mb={2} align="center">
                            {userId ? 'Atualizar' : 'Cadastrar'} Usuário
                        </Typography>
                    )}
                    <Box
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        alignItems="center"
                        width="100%"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mb={isMobile ? 2 : 0}
                            mr={isMobile ? 0 : 4}
                        >
                            <Avatar src={avatar} sx={{ width: isMobile ? 200 : 300, height: isMobile ? 200 : 300, mb: 2 }} />
                            <label htmlFor="avatar-upload">
                                <Input
                                    accept="image/*"
                                    id="avatar-upload"
                                    type="file"
                                    onChange={handleAvatarChange}
                                    sx={{ display: 'none' }}
                                />
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                    <PhotoCamera />
                                </IconButton>
                            </label>
                        </Box>
                        <Box component="form" onSubmit={handleSubmit} display="flex" flexDirection="column" width="100%">
                            {!isMobile && (
                                <Typography variant="h5" component="div" mb={2} align="center">
                                    {userId ? 'Atualizar' : 'Cadastrar'} Usuário
                                </Typography>
                            )}
                            <TextField
                                label="Nome"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                margin="normal"
                                fullWidth
                                required
                            />
                            <TextField
                                label="Email"
                                variant="outlined"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                margin="normal"
                                fullWidth
                                required
                            />
                            <TextField
                                label="Celular"
                                variant="outlined"
                                value={cellphone}
                                onChange={handleCellphoneChange}
                                margin="normal"
                                fullWidth
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="user-type-label">Tipo de Usuário</InputLabel>
                                <Select
                                    labelId="user-type-label"
                                    id="user-type"
                                    value={userType}
                                    label="Tipo de Usuário"
                                    onChange={(e) => setUserType(e.target.value)}
                                >
                                    {Object.keys(UserTypeEnum).map((key) => (
                                        <MenuItem key={key} value={UserTypeEnum[key]}>
                                            {translateUserType(UserTypeEnum[key])}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                label="Senha"
                                variant="outlined"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                margin="normal"
                                fullWidth
                                required={userId == undefined}
                                error={errorMessage !== ''}
                                helperText={errorMessage}
                            />
                            <TextField
                                label="Confirmação de Senha"
                                variant="outlined"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                margin="normal"
                                fullWidth
                                required={userId == undefined}
                                error={errorMessage !== ''}
                                helperText={errorMessage}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 2 }}
                                disabled={loading}
                                endIcon={loading && <CircularProgress size={20} />}
                                className="confirm-button"
                            >
                                {userId ? 'Atualizar' : 'Cadastrar'}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ToastContainer />
        </>
    );
};

export default UserPage;
