export default function IbrasurfLogo({ fillColor, width, height }) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox="0 0 400 400"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
                Created by potrace 1.16, written by Peter Selinger 2001-2019
            </metadata>
            <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
                fill={fillColor} stroke="none">
                <path d="M1202 3445 c-22 -16 -21 -16 38 -74 43 -42 60 -66 60 -85 l0 -26
-102 0 c-99 -1 -132 -4 -313 -30 -154 -22 -379 -82 -458 -122 l-37 -18 0 -141
c0 -171 9 -208 95 -384 149 -303 390 -537 763 -744 106 -59 125 -65 61 -19
-268 190 -451 376 -586 593 -50 81 -113 207 -140 280 -18 52 -63 211 -63 225
0 24 167 82 340 119 252 54 610 56 850 5 369 -79 687 -290 882 -586 120 -181
182 -354 200 -559 l12 -127 55 28 c104 52 246 48 360 -10 138 -70 225 -209
238 -383 l6 -79 -429 5 c-550 7 -772 20 -1109 67 -329 45 -556 94 -840 182
-104 31 -421 152 -439 167 -6 5 -20 11 -32 15 -11 3 -62 29 -114 56 -52 28
-98 50 -102 50 -4 0 -8 -144 -8 -320 l0 -320 1615 0 1615 0 0 52 c0 70 -26
209 -56 291 -84 238 -289 385 -523 375 -62 -2 -98 -9 -128 -24 -23 -12 -45
-19 -48 -16 -3 3 -5 31 -5 63 0 148 -74 490 -130 598 -4 9 -20 44 -35 79 -57
136 -174 319 -271 429 -76 85 -190 163 -238 163 -35 0 -40 -15 -21 -56 16 -35
24 -128 12 -141 -4 -4 -18 2 -31 13 -13 10 -59 48 -102 83 -185 151 -394 263
-585 315 -112 30 -222 35 -257 11z"/>
                <path d="M1377 2769 c-72 -17 -143 -58 -171 -98 -73 -105 -29 -314 111 -524
99 -149 350 -381 520 -479 171 -100 420 -161 533 -131 l35 9 -81 41 c-370 185
-759 616 -868 961 -33 107 -33 126 3 151 29 21 128 51 168 51 13 0 23 4 23 9
0 19 -201 27 -273 10z"/>
                <path d="M1780 2704 c0 -9 6 -30 14 -47 8 -18 31 -70 51 -117 137 -320 405
-669 636 -827 l77 -53 11 45 c15 58 14 77 -5 154 -72 289 -383 664 -673 810
-95 49 -111 53 -111 35z"/>
                <path d="M613 1113 l-33 -4 0 -285 c0 -262 1 -285 18 -292 9 -4 69 -7 132 -7
99 0 121 3 160 22 62 31 90 75 90 142 0 65 -20 102 -72 131 -39 22 -39 22 -19
37 79 55 63 202 -27 240 -37 16 -185 26 -249 16z m176 -88 c85 -43 30 -172
-66 -153 -15 3 -18 15 -21 86 l-3 82 31 0 c17 0 44 -7 59 -15z m13 -245 c43
-12 58 -34 58 -83 0 -45 -33 -74 -85 -77 -79 -5 -75 -9 -75 85 l0 85 33 0 c17
0 49 -5 69 -10z"/>
                <path d="M1053 1113 l-33 -4 0 -290 0 -289 55 0 55 0 0 245 0 245 43 0 c101 0
132 -112 49 -180 -20 -16 -42 -30 -49 -30 -21 0 -16 -16 54 -151 l66 -129 64
0 c34 0 63 1 63 3 0 2 -24 49 -54 104 -72 135 -72 134 -35 169 44 42 59 76 59
137 0 69 -38 126 -101 151 -43 17 -176 28 -236 19z"/>
                <path d="M2071 1108 c-52 -14 -80 -37 -103 -81 -36 -72 -10 -173 54 -204 48
-24 117 -53 127 -53 15 0 51 -50 51 -71 0 -10 -11 -31 -24 -46 -23 -26 -29
-28 -98 -27 -40 0 -83 6 -95 12 -26 14 -30 9 -39 -46 -6 -39 -5 -42 22 -53 16
-7 72 -13 124 -13 84 -1 100 2 139 24 70 40 86 69 86 155 0 99 -15 118 -133
170 -96 42 -119 65 -108 109 9 35 64 50 136 36 71 -13 67 -15 80 26 15 44 9
51 -59 64 -64 12 -114 12 -160 -2z"/>
                <path d="M2883 1113 l-33 -4 0 -290 0 -289 55 0 55 0 0 245 0 245 45 0 c63 0
95 -32 95 -93 0 -36 -6 -48 -32 -74 -18 -17 -43 -36 -55 -42 -23 -12 -23 -12
45 -144 l67 -132 63 -3 c34 -2 62 0 62 3 0 3 -27 59 -60 123 l-60 117 29 25
c49 42 64 76 64 141 0 51 -4 65 -29 97 -45 55 -88 73 -191 77 -48 2 -103 1
-120 -2z"/>
                <path d="M390 820 l0 -290 60 0 60 0 0 290 0 290 -60 0 -60 0 0 -290z" />
                <path d="M1609 1083 c-6 -16 -19 -55 -29 -88 -10 -33 -40 -134 -68 -225 -27
-91 -55 -182 -60 -203 l-11 -38 57 3 c53 3 57 4 63 33 18 84 20 85 113 85 52
0 88 -4 92 -11 4 -6 12 -32 18 -58 l12 -46 62 -3 c34 -2 62 0 62 3 0 3 -8 31
-19 63 -10 31 -33 107 -51 167 -17 61 -38 128 -45 150 -7 22 -23 75 -36 118
l-23 77 -63 0 c-60 0 -64 -2 -74 -27z m99 -213 c12 -47 25 -95 28 -107 6 -22
4 -23 -55 -23 -71 0 -70 -3 -41 110 30 115 32 122 40 113 4 -4 17 -46 28 -93z"/>
                <path d="M2362 893 c5 -255 14 -290 89 -340 38 -25 51 -28 129 -28 76 0 91 3
123 25 21 14 47 41 60 60 20 33 22 50 27 268 l5 232 -57 0 -58 0 0 -186 c0
-256 -15 -304 -98 -304 -45 0 -68 14 -89 53 -14 27 -17 64 -17 234 l-1 203
-59 0 -58 0 4 -217z"/>
                <path d="M3290 820 l0 -290 55 0 55 0 0 120 0 120 78 0 c42 0 83 4 90 9 8 5
12 23 10 47 l-3 39 -85 5 -85 5 -3 68 -3 67 111 0 111 0 -3 48 -3 47 -162 3
-163 2 0 -290z"/>
            </g>
        </svg>

    );
}