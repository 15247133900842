import api from './apiConfig';

export const login = async (data) => {
    try {
        const response = await api.post('/login', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const refreshToken = async () => {
    try {
        const response = await api.post('/refresh-token');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const logout = async () => {
    try {
        const response = await api.post('/logout');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const userRegister = async (data) => {
    try {
        const response = await api.post('/users', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const userUpdate = async (data, userId) => {
    try {
        const response = await api.put(`/users/${userId}`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const listUsers = async (queryParams) => {
    try {
        const response = await api.get('/users', {
            params: queryParams
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getUser = async (userId) => {
    try {
        const response = await api.get(`/users/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUser = async (userId) => {
    try {
        const response = await api.delete(`/users/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const listUserVideos = async (userId, queryParams) => {
    try {
        const response = await api.get(`users/${userId}/videos`, {
            params: queryParams
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getUserVideo = async (userId, videoId) => {
    try {
        const response = await api.get(`/users/${userId}/videos/${videoId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const userVideoRegister = async (userId, data) => {
    try {
        const response = await api.post(`users/${userId}/videos`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const userVideoUpdate = async (userId, videoId, data) => {
    try {
        const response = await api.put(`users/${userId}/videos/${videoId}`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const userVideoDelete = async (userId, videoId) => {
    try {
        const response = await api.delete(`users/${userId}/videos/${videoId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const userVideoProcessKeypoints = async (data) => {
    try {
        const response = await api.post(`/analyzer/generate-video-keypoints`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const listInstructorsVideos = async (queryParams) => {
    try {
        const response = await api.get(`users/instructors/videos`, {
            params: queryParams
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getInstructorVideo = async (videoId) => {
    try {
        const response = await api.get(`users/instructors/videos/${videoId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const compareVideos = async (data) => {
    try {
        const response = await api.post(`/analyzer/compare-videos`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAnalysis = async (analysisId) => {
    try {
        const response = await api.get(`analyzer/${analysisId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateAnalysis = async (analysisId, payload) => {
    try {
        const response = await api.put(`analyzer/${analysisId}`, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteAnalysis = async (analysisId) => {
    try {
        const response = await api.delete(`analyzer/${analysisId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const listAnalysis = async (queryParams) => {
    try {
        const response = await api.get('/analysis', {
            params: queryParams
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const listWaveTypes = async (queryParams) => {
    try {
        const response = await api.get('/wave-types', {
            params: queryParams
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};