import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    AppBar,
    Box,
    Button,
    Container,
    InputBase,
    Toolbar
} from '@mui/material';
import { Search as SearchIcon, Add as AddIcon } from '@mui/icons-material';
import Navbar from "../../components/Navbar";
import { Link } from 'react-router-dom';
import { userVideoDelete, listUserVideos } from "../../utils/apiRoutes";
import { ToastContainer } from 'react-toastify';
import { createNotification } from '../../utils/alerts';
import VideoList from '../../components/VideosList';
import { userData } from '../../utils/userData';
import 'react-toastify/dist/ReactToastify.css';
import styles from './list-videos.module.scss';

let timeoutId;

const MyVideos = () => {
    const isMobile = window.innerWidth < 768;
    const [user, setUser] = useState(userData());
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(9);
    const [videos, setVideos] = useState([]);
    const [videoToDelete, setVideoToDelete] = useState(null);
    const [emptyVideos, setEmptyVideos] = useState(false);
    const [comparedVideoId, setComparedVideoId] = useState(null);
    const [blockStep, setBlockStep] = useState(false);

    const getVideos = async (userId, searchTerm) => {
        try {
            const response = await listUserVideos(userId, {
                search: searchTerm === '' ? undefined : searchTerm,
                perPage: itemsPerPage
            });
            setVideos(response.videos);
        } catch (error) {
            createNotification('failed', 'Falha ao buscar os vídeos!', isMobile);
        }
    };

    useEffect(() => {
        getVideos(user.id)
    }, []);

    const loadMoreVideos = useCallback(async (userId) => {
        if (loading || emptyVideos) return;
        setLoading(true);
        const nextPage = page + 1;

        const response = await listUserVideos(userId, {
            search: searchTerm === '' ? undefined : searchTerm,
            page: nextPage,
            perPage: itemsPerPage
        });

        setTimeout(() => {
            setVideos(prevVideos => [...prevVideos, ...response.videos]);
            setPage(nextPage);
            setLoading(false);
            setEmptyVideos(response.videos.length == 0)
        }, 500);
    });

    const handleSearchChange = (event) => {
        clearTimeout(timeoutId);
        setSearchTerm(event.target.value);

        timeoutId = setTimeout(() => {
            getVideos(user.id, event.target.value);
        }, 1000);
    };

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
        if (bottom) {
            loadMoreVideos(user.id);
        }
    };

    const handleDeleteClick = (event, userVideoId) => {
        setAnchorEl(event.currentTarget);
        setVideoToDelete(userVideoId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setVideoToDelete(null);
    };

    const handleConfirmDelete = async () => {
        if (videoToDelete) {
            await userVideoDelete(user.id, videoToDelete);
            setVideos(videos.filter(video => video.id !== videoToDelete));
            createNotification('success', 'Vídeo excluído!', isMobile);
        }
        handleClose();
    };

    return (
        <>
            <Navbar />
            <Container className={styles["container"]}>
                <AppBar position="static" className={styles["appbar"]}>
                    <Toolbar className={styles["toolbar"]}>
                        {isMobile && (
                            <>
                                <Button
                                    variant="contained"
                                    className={styles["add-button"]}
                                    endIcon={<AddIcon />}
                                    component={Link}
                                    to="/my-videos/register"
                                >
                                    Cadastrar
                                </Button>
                                <Box className={styles["search-box"]}>
                                    <InputBase
                                        className={styles["search-input"]}
                                        placeholder="Pesquisar..."
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        inputProps={{ 'aria-label': 'pesquisar' }}
                                        startAdornment={<SearchIcon />}
                                    />
                                </Box>
                            </>
                        )}
                        {!isMobile && (
                            <>
                                <Box className={styles["search-box"]}>
                                    <InputBase
                                        className={styles["search-input"]}
                                        placeholder="Pesquisar..."
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        inputProps={{ 'aria-label': 'pesquisar' }}
                                        startAdornment={<SearchIcon />}
                                    />
                                </Box>
                                <Button
                                    variant="contained"
                                    className={styles["add-button"]}
                                    endIcon={<AddIcon />}
                                    component={Link}
                                    to="/my-videos/register"
                                >
                                    Cadastrar
                                </Button>
                            </>
                        )}
                    </Toolbar>
                </AppBar>
                <VideoList
                    videos={videos}
                    handleScroll={handleScroll}
                    loading={loading}
                    handleClose={handleClose}
                    handleDeleteClick={handleDeleteClick}
                    handleConfirmDelete={handleConfirmDelete}
                    anchorEl={anchorEl}
                    setComparedVideoId={setComparedVideoId}
                    setBlockStep={setBlockStep}
                    videosFrom='student'
                />
            </Container>
            <ToastContainer />
        </>
    );
};

export default MyVideos;
