import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LoginPage from '../pages/login';
import HomePage from '../pages/home';
import ListUsers from '../pages/users';
import UserPage from '../pages/users/user.js';
import MyVideos from '../pages/myVideos';
import RegisterVideo from '../pages/myVideos/register.js'
import DetailsVideo from '../pages/myVideos/details.js'
import ListAnalysis from '../pages/analysis';
import ComparePage from '../pages/analysis/compare.js'
import AnalysisDetailsPage from "../pages/analysis/details.js";

const PrivateRoute = ({ element, ...rest }) => {
    const { user, loading } = useAuth();

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return user ? element : <Navigate to="/login" />;
};

const PublicRoute = ({ element, restricted, ...rest }) => {
    const { user, loading } = useAuth();

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return user && restricted ? <Navigate to="/home" /> : element;
};

const RoutesConfig = () => {
    return (
        <Routes>
            <Route path="/login" element={<PublicRoute restricted={true} element={<LoginPage />} />} />
            <Route path="/home" element={<PrivateRoute element={<HomePage />} />} />
            <Route path="/users" element={<PrivateRoute element={<ListUsers />} />} />
            <Route path="/users/register" element={<PrivateRoute element={<UserPage />} />} />
            <Route path="/users/:userId" element={<PrivateRoute element={<UserPage />} />} />
            <Route path="/my-videos" element={<PrivateRoute element={<MyVideos />} />} />
            <Route path="/my-videos/register" element={<PrivateRoute element={<RegisterVideo />} />} />
            <Route path="/my-videos/:videoId" element={<PrivateRoute element={<DetailsVideo />} />} />
            <Route path="/analysis" element={<PrivateRoute element={<ListAnalysis />} />} />
            <Route path="/analysis/compare" element={<PrivateRoute element={<ComparePage />} />} />
            <Route path="/analysis/:analysisId" element={<PrivateRoute element={<AnalysisDetailsPage />} />} />

            <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
    );
};

export default RoutesConfig;
