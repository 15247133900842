import React, { createContext, useState, useContext, useEffect } from 'react';
import {
    login as loginApi,
    refreshToken as refreshTokenApi,
    logout as logoutApi
} from '../utils/apiRoutes';
import jwtEncode from 'jwt-encode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await refreshTokenApi();
                setUser(response);
            } catch (error) {
                setUser(null);
            } finally {
                setLoading(false);
            }
        };

        checkSession();
    }, []);

    const login = async (data) => {
        try {
            const response = await loginApi(data);
            const userObj = jwtEncode(response, process.env.REACT_APP_SECRET_KEY)

            localStorage.setItem('user', userObj);

            setUser(response);
        } catch (error) {
            throw error;
        }
    };

    const logout = async () => {
        try {
            await logoutApi();
            setUser(null);
            localStorage.removeItem('user');
            localStorage.removeItem('comparedVideoId');
            localStorage.removeItem('instructorVideoId');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};