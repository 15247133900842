const StatusEnum = {
    TO_PROCESS: 'TO_PROCESS',
    PROCESSING: 'PROCESSING',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED'
};

const translateStatus = (value) => {
    const translations = {
        TO_PROCESS: 'À Processar',
        PROCESSING: 'Processando',
        COMPLETED: 'Completo',
        FAILED: 'Falhou'
    };
    return translations[value] || value;
};

export { StatusEnum, translateStatus };
