const WaveSideEnum = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT'
};

const translateWaveSide = (value) => {
    const translations = {
        LEFT: 'Esquerda',
        RIGHT: 'Direita'
    };
    return translations[value] || value;
};

export { WaveSideEnum, translateWaveSide };
