import { jwtDecode } from 'jwt-decode';

export const userData = () => {
    const userInfo = localStorage.getItem('user');

    if (!userInfo)
        return null

    return jwtDecode(userInfo);
};
